function uuid() {
  const s = [];
  const hexDigits = '0123456789abcdef';
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
  // @ts-ignore
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-';

  const uuid = s.join('');
  return uuid;
}

export async function track(extraProperties: any) {
  const url = 'https://data.js.design';

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      theme: 'behaviour',
      action: 'TencentAd',
      distinct_id: uuid(),
      user_id: '',
      time: +new Date(),
      hrefUrl: window.location.href,
      properties: {
        user_agent: window.navigator.userAgent,
        device_type: 'web',
        referrer: document.referrer,
        ...extraProperties
      }
    })
  }

  fetch(url, options)
}