import { createPortal } from "react-dom"
import "./style.css"
import Image19 from "src/assets/19.png"
import Image20 from "src/assets/20.svg"
import { useEffect, useRef } from "react"

const Modal = ({ open, onClose }: any) => {
  const modalRef = useRef<HTMLDivElement>(null)

  const InternalModal = (
    <div className="modal" ref={modalRef}>
      <div className="mask"></div>

      <div className="container">
        <img src={Image19} width="100%" />
        <img src={Image20} className="icon-close" onClick={onClose} />
      </div>
    </div>
  )

  useEffect(() => {
    const addNoscroll = () => {
      document.documentElement.style.touchAction = 'none'
      document.documentElement.classList.add('noscroll')
      document.body.style.touchAction = 'none'
    }

    const removeNoscroll = () => {
      document.documentElement.style.touchAction = 'initial'
      document.documentElement.classList.remove('noscroll')
      document.body.style.touchAction = 'initial'
    }

    if (open) {
      addNoscroll()
      setTimeout(() => {
        modalRef.current?.classList.add("slide-in")
      }, 0)
    } else {
      removeNoscroll()
      setTimeout(() => {
        modalRef.current?.classList.remove("slide-in")
      }, 0)
    }
  }, [open])

  return open
    ? createPortal(InternalModal, document.querySelector("#root") as Element)
    : null
}

export default Modal
