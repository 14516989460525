import { Card } from "./components/Card"
import "./App.css"
import Svg0 from "./assets/0.svg"
import Image1 from "./assets/1.png"
import Image2 from "./assets/2.png"
import Image16 from "./assets/16.svg"
import Image17 from "./assets/17.svg"
import Image5 from "./assets/5.svg"
import Image6 from "./assets/6.png"
import Image7 from "./assets/7.png"
import Image8 from "./assets/8.png"
import Image14 from "./assets/14.png"
import Image15 from "./assets/15.png"
import Image9 from "./assets/9.png"
import Image10 from "./assets/10.png"
import Image11 from "./assets/11.png"
import Image12 from "./assets/12.png"
import Image22 from "./assets/22.png"
import Image23 from "./assets/23.png"
import { Price } from "./components/Price"
import { Modal } from "./components/Modal"
import { useEffect, useState } from "react"
import { track } from "./handle/dataware"

const App = () => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const imgs = document.querySelectorAll('.track')
    imgs.forEach((elem: any, index) => {
      elem.addEventListener('click', () => {
        setOpen(true)
      
        const location = elem?.dataset?.location
        track({ location })
      })
    })
  }, [])

  return (
    <>
      <img
        loading="lazy"
        data-location='banner'
        className="banner track"
        src={Image23}
        width="100%"
      />

      <div className="bg-1">
        <div className="line-3">
          <div className="line-3_container">
            <div className="row-0">
              <span>7 天</span>
              <img loading="lazy" src={Image16} alt="+" />
              <div className="column">
                <span>每天</span>
                <span>15 分钟</span>
                <span>实操</span>
              </div>
              <img loading="lazy" src={Image17} alt="=" />
              <div className="column">
                <span>一个</span>
                <span>高效</span>
                <span>设计技能</span>
              </div>
              <img loading="lazy" src={Image16} alt="+" />
              <div className="column">
                <span>一套</span>
                <span>高质量</span>
                <span>设计作品</span>
              </div>
              <img loading="lazy" src={Image16} alt="+" />
              <div className="column">
                <span>打卡</span>
                <span>奖品</span>
              </div>
            </div>

            <div className="row-1">
              <h2>适合人群：</h2>
              <span>工作经验 1 - 3 年的设计师</span>
            </div>

            <div className="row-1">
              <h2>解决问题：</h2>
              <span>设计思维固化，配色细节差，设计表达能力差等</span>
            </div>
          </div>
        </div>

        <div className="line-4">
          <Card title="打卡登陆指南" subtitle="CLOCK IN GUIDE">
            <div className="column">
              <span>
                点击立刻参与，关注<b>打卡星球公众号</b>
              </span>
              <img loading="lazy" src={Image5} alt="DOWN" width={26} />
              <span>
                加入<b>专属微信互动群</b>，获取活动链接
              </span>
              <img loading="lazy" src={Image5} alt="DOWN" width={26} />
              <span>
                PC 端打开活动链接，<b>注册即时设计账号</b>
              </span>
              <img loading="lazy" src={Image5} alt="DOWN" width={26} />
              <span>
                进入活动界面，领取专属<b>素材与教程</b>
              </span>
              <img loading="lazy" src={Image5} alt="DOWN" width={26} />
              <span>
                打开<b>工作台</b>开始你的首次设计打卡{" "}
              </span>

              <img
                loading="lazy"
                data-location='map'
                className="mfcj track"
                src={Image1}
                width={266}
                draggable={false}
              />
            </div>
          </Card>
        </div>

        <div className="line-5">
          <Card title="热门活动主题" subtitle="HOT ACTIVITY THEME">
            <div className="column">
              <img
                loading="lazy"
                className="cover"
                src={Image6}
                alt="渐变彩色轻拟物图标"
                width='100%'
              />
              <img
                loading="lazy"
                data-location='firstactivity'
                className="mfcj track"
                src={Image1}
                width={266}
                draggable={false}
              />

              <img
                loading="lazy"
                className="cover"
                src={Image7}
                alt="潮流插画设计应用"
                width='100%'
              />
              <img
                loading="lazy"
                data-location='secondactivity'
                className="mfcj track"
                src={Image1}
                width={266}
                draggable={false}
              />

              <img
                loading="lazy"
                className="cover"
                src={Image8}
                alt="酸性风格元素设计"
                width='100%'
              />
              <img
                loading="lazy"
                data-location='thirdactivity'
                className="mfcj track"
                src={Image1}
                width={266}
                draggable={false}
              />
            </div>
          </Card>
        </div>

        <div className="line-6">
          <Card title="丰厚奖品" subtitle="RICH PRIZES">
            <div className="jser">
              <img loading="lazy" src={Image14} alt="LEFT" width={20} />
              <h2>优秀JSER 丰厚大礼</h2>
              <img loading="lazy" src={Image15} alt="RIGHT" width={20} />
            </div>

            <div className="grid">
              <Price
                name="优秀 JSER"
                cover={Image9}
                price1="Elgato 6 键控制器"
                price2="字由会员年卡"
              />
              <Price
                name="潜力 JSER"
                cover={Image10}
                price1="字体书《街机游戏字体》"
                price2="字由会员年卡"
              />
              <Price
                name="邀请 JSER"
                cover={Image11}
                price1="小米智能台灯"
                price2="字由会员季卡"
              />
              <Price
                name="交个朋友奖"
                cover={Image12}
                price1="AOC 显示器"
                price2="或 国行 switch 二选一"
              />
            </div>
          </Card>
          <img
            loading="lazy"
            className="line-6_icon"
            src={Image22}
            width="103"
          />
        </div>
      </div>

      <Modal open={open} onClose={() => setOpen(false)} />
    </>
  )
}

export default App
