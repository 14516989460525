import "./style.css"

const Price = ({ name, cover, offset, price1, price2 }: any) => {
  return (
    <div className="price">
      <div className="name">{name}</div>
      <div className="price-content">
        <div className="price-content_container">
          <img src={cover} alt="优秀 JSER" className="cover" loading='lazy'/>
        </div>
        <h3>{price1}</h3>
        <h3>{price2}</h3>
      </div>
    </div>
  )
}

export default Price
