import Image4 from "src/assets/4.png"
import "./style.css"

const Card = (props: any) => {
  const { title, subtitle, children } = props

  return (
    <div className="card">
      <div className="title">
        <img src={Image4} alt="STAR" width={39} />
        <h2>{title}</h2>
        <span>{subtitle}</span>
      </div>
      <div className="content">{children}</div>
    </div>
  )
}

export default Card
